<template>
  <AImage src="https://f.hubspotusercontent00.net/hubfs/2480959/PC_Hero_3-1.jpg" max-height="100vh">
    <div class="d-flex align-center justify-center flex-column" style="height: 100%">
      <v-icon class="mb-4" color="white" x-large>mdi-wrench-clock</v-icon>
      <h1 class="white--text">Our site is under Maintenance</h1>
    </div>
  </AImage>
</template>

<script>
import AImage from '@/components/atoms/AImage.vue';

export default {
  components: {
    AImage
  }
};
</script>
